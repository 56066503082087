import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["hamburger"]
  
  connect() {
    this.close = this.close.bind(this)
    this.element.querySelectorAll('a').forEach((a) => {
      a.addEventListener('click',this.close)
    })
  }

  close() {
    document.body.classList.remove('open_modal')
    this.hamburgerTarget.classList.remove('active')
    document.getElementById('menu').classList.add('hidden')
  }

  toggle(e) {
    document.body.classList.toggle('open_modal')
    document.getElementById('menu').classList.toggle('hidden')
  }
}
