import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "check", "submitter", "form", "wrapper", "input", "results" ]
  field = ""
  timeout = null
  gender = null

  connect() {
  }

  show(ev) {
    this.inputTarget.value = ""
    this.wrapperTarget.classList.remove("hidden")
    this.inputTarget.focus()
    this.field = ev.target.dataset.field
    this.gender = ev.target.dataset.gender
    if (this.gender === "Frauen") {
      this.formTarget.querySelector('#c').value = "2"
    } else {
      this.formTarget.querySelector('#c').value = "1"
    }
  }

  emptyFields() {
    let empty = 0
    document.querySelectorAll('.voting_ids').forEach((i) => {
      if (i.value === "") {
        empty++;
      }
    })
    return empty !== 0
  }

  accept(ev) {
    if (ev.target.checked) {
      if (!this.emptyFields()) {
        this.submitterTarget.removeAttribute('disabled')
      }
    } else {
      this.submitterTarget.setAttribute('disabled','disabled')
    }
  }

  submission(ev) {
    window.scrollTo(0,0)
    return true
  }

  hide() {
    this.wrapperTarget.classList.add("hidden")
    this.inputTarget.value = ""
    this.resultsTarget.innerHTML = ""
  }

  select(ev) {
    let target = null
    if (ev.target.tagName === "MARK") {
      target = ev.target.parentElement
    } else {
      target = ev.target
    }

    let field = this.element.querySelector(`#${this.field}_field`)
    if (field) {
      field.innerText = target.innerText
      field.parentElement.classList.add("bw_bg_yellow")
    }
    let input = this.element.querySelector(`#vote_${this.field}`)
    if (input) {
      input.value = target.dataset.pro
    }

    this.hide()
    if(!this.emptyFields() && this.checkTarget.checked) {
      this.submitterTarget.removeAttribute('disabled')
    }
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 350)
  }
}