import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "slider", "result", "serverVoted", "counter" ]
  connect() {
    const clientVoted = localStorage.getItem("voted_cat1");
    if (clientVoted != null) {
      let voted = clientVoted.split(",")
      if (voted.indexOf(document.getElementById('rform').dataset.trikot) != -1) {
        window.location = "/trikots/danke"
      }
      this.counterTarget.innerHTML = clientVoted.split(",").length
      this.serverVotedTarget.value = clientVoted
    }
  }

  submit(e) {
    var clientVoted = localStorage.getItem("voted_cat1");
    var arr = []
    if (clientVoted != null) {
      arr = clientVoted.split(",")
    }
    arr.push(e.target.dataset.trikot)

    localStorage.setItem("voted_cat1", arr.toString());
  }

  change() {
    this.resultTarget.innerText = this.sliderTarget.value 
  }
  
}