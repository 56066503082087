import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recorder", "recordingButton", "stopButton", "player", "audio", "start", "stop", "time", "consent", "form", "fileinput", "thanks", "loading"]
  static classes = ["recording"]

  mediaRecorder = null
  recording = false
  secondsIntervalId = null
  blob = null

  incrementSeconds() {
    let el = document.querySelector('[data-voice_messages-target="time"]')
    let s = parseInt(el.dataset.seconds)
    s += 1
    el.innerHTML = (s-(s%=60))/60+(9<s?':':':0')+s
    el.dataset.seconds = s
  }

  send() {  
    const formData = new FormData(this.formTarget)
    formData.append('audio', this.blob);

    this.recorderTarget.classList.add('hidden')
    this.consentTarget.classList.add('hidden')
    this.loadingTarget.classList.remove('hidden')

    fetch('/voice_messages', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      this.loadingTarget.classList.add('hidden')
      this.thanksTarget.classList.remove('hidden')
    })
    .catch(error => {
      console.log(error)
    })
  }

  startRecord() {
    this.recordingButtonTarget.classList.add(this.recordingClass)
    this.recordingButtonTarget.innerHTML = "RECORDING"
    this.mediaRecorder.start()
    this.recording = "false"
    this.timeTarget.dataset.seconds = 0
    this.secondsIntervalId = setInterval(this.incrementSeconds, 1000)
  }

  stopRecord() {
    if (this.recording == false) { return }

    this.recordingButtonTarget.classList.remove(this.recordingClass)
    this.recordingButtonTarget.innerHTML = "RECORD (REPLACING)"
    this.mediaRecorder.stop()
    this.recording = false
    clearInterval(this.secondsIntervalId)
    this.secondsIntervalId = null
    this.seconds = 0
    this.playerTarget.classList.remove("hidden")
    this.consentTarget.classList.remove('hidden')
  }

  removeRecord() {
    this.blob = null
    this.audioTarget.src = ""
    this.timeTarget.dataset.seconds = 0
    this.timeTarget.innerHTML = "0:00"
    this.playerTarget.classList.add('hidden')
    this.consentTarget.classList.add('hidden')
  }

  setupUpload() {
    document.querySelectorAll('.setupButtons').forEach(function(b) {b.classList.add('hidden')})
  }

  setupRecord(event) {
    document.querySelectorAll('.setupButtons').forEach(function(b) {b.classList.add('hidden')})

    navigator.mediaDevices.getUserMedia({ audio: true }).then(function (mediaStreamObj) { 
        this.mediaRecorder = new MediaRecorder(mediaStreamObj);
      
        let dataArray = [];
        this.mediaRecorder.ondataavailable = function (ev) {
          dataArray.push(ev.data);
        }        
 
        this.mediaRecorder.onstop = function (ev) {
          this.blob = new Blob(dataArray, { 'type': 'audio/mp3;' })
          dataArray = []
          let audioSrc = window.URL.createObjectURL(this.blob)
          this.audioTarget.src = audioSrc
        }.bind(this)

        this.recorderTarget.classList.remove("hidden")

      }.bind(this)).catch(function (err) {
        console.log(err.name, err.message);
      });
  }

  connect() {
    
  }
}