import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "currentTime", "title", "duration", "pause", "icon", "close", "audio", "slider"]
  static values = { url: String, id: String, title: String}

  connect() {
    this.audioTarget.onloadedmetadata = function () {
      this.sliderTarget.max = this.audioTarget.duration
    }.bind(this)

    this.audioTarget.ontimeupdate = function () {
      const newValue = this.audioTarget.currentTime / this.audioTarget.duration * 100
      this.sliderTarget.value = this.audioTarget.currentTime
      const percentage = this.sliderTarget.value / this.sliderTarget.max * 100 
      this.sliderTarget.style.background = 'linear-gradient(to right, #000, #000 ' + percentage + '%, rgba(31, 41, 55) ' + percentage + '%, rgba(31, 41, 55) 100%)'
      this.currentTimeTarget.textContent = formatTime(this.audioTarget.currentTime)
    }.bind(this)

    this.audioTarget.addEventListener('ended', function () {
      slider.style.background = "none"
      this.currentTime = 0
    })
  }

  idValueChanged() {
    if(this.idValue !== "") {
      this.startEpisode()
    }
  }

  move (event) {
    this.audioTarget.currentTime = event.target.value
  }

  formatTime(time) {
    const min = Math.floor(time % 60);
    const hours = Math.floor(time / 3600);
    const sec = Math.floor(time % 60);
    return hours + ':' + min + ':' + ((sec<10) ? ('0' + sec) : sec);
  }

  startEpisode() {
    const currentEpElement = document.getElementById(`episode-${this.idValue}`)
    if (window.goatcounter !== undefined) {
      window.goatcounter.count({path: "Ep. started: " + currentEpElement.dataset.title, event: true})
    }

    this.audioTarget.setAttribute('src', currentEpElement.dataset.audioSrc)
    this.durationTarget.textContent = formatTime(parseInt(currentEpElement.dataset.duration))
    this.titleTarget.textContent = currentEpElement.dataset.title
    if (this.playerTarget.classList.contains('hidden')) {
      this.playerTarget.classList.remove('hidden')
    }
    this.iconTarget.attributes.d.value = "M0 0h6v24H0zM12 0h6v24h-6z";
    this.audioTarget.play()

    document.querySelectorAll('.current-episode-btn').forEach( e => { e.classList.add('hidden') })
  }

  playPause() {
    if (this.audioTarget.paused) {
      this.iconTarget.attributes.d.value = "M0 0h6v24H0zM12 0h6v24h-6z";
      this.audioTarget.play()
    } else {
      this.iconTarget.attributes.d.value = "M18 12L0 24V0";
      this.audioTarget.pause()
    }
  }

  timePlus(event) {
    const pa = this.audioTarget
    if (pa.paused) { return }
    pa.currentTime = pa.currentTime + parseInt(event.currentTarget.dataset.time)
  }

  close() {
    this.urlValue = ""
    this.idValue = ""
    this.audioTarget.pause();
    this.playerTarget.classList.add('hidden')
    const episode_btn = document.querySelector(`.episode${ window.innerWidth > 768 ? "-desktop-btn" : "-mobile-btn"}`)
    if (episode_btn) {
        episode_btn.classList.remove("hidden")}
  }

  setSpeed(event) {
    const pa = this.audioTarget
    const speed = parseFloat(event.currentTarget.dataset.speed)
    if (pa.paused) { return }
    const speedButtons = document.querySelectorAll('.player-speed-buttons')

    speedButtons.forEach(function(el){
        el.classList.remove('active')
    })
    if (pa.playbackRate === speed) {
        pa.playbackRate = 1
    } else {
        pa.playbackRate = speed
        event.currentTarget.classList.add('active')
    }
  }
}
