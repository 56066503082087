import { Application } from "@hotwired/stimulus"

const application = Application.start()

application.debug = false
window.Stimulus   = application

export { application }


document.addEventListener("turbo:load", () => {
    const clientLocale = localStorage.getItem('locale')
    const browserLocale = navigator.language || navigator.userLanguage;
    if (clientLocale == "de" && browserLocale != "de" && !window.location.search.includes("locale=de")) {
        window.location = "/switch_locale?new_locale=de";
    }

    const playEpBtn = document.querySelector('#play-episode-btn')
    const currentEpisodeElement = document.getElementById('current-episode')
    if (playEpBtn) {
        if (currentEpisodeElement) {
            playEpBtn.classList.remove("hidden")
        } else {
            playEpBtn.classList.add("hidden")
        }
    }
})

document.addEventListener('turbo:visit', () => {
    if (window.goatcounter != undefined) {
        window.goatcounter.count()
    }
})

window.formatTime = function(time) {
    const min = Math.floor(time / 60);
    const sec = Math.floor(time % 60);
    return min + ':' + ((sec<10) ? ('0' + sec) : sec);
}